<template>
    <div class="app-container order-detail">
        <RoundCornerContainer class="order-header">
            <van-row :span="24">
                <van-col :span="24" class="product-name">{{data.productName}}</van-col>
                <van-col :span="20">
                    <div class="order-info-item">
                        <span class="label">订单号:</span>
                        <span class="value">{{data.orderNo}}</span>
                    </div>
                    <div class="order-info-item" v-if="data.policyNo && data.statusGroup >= 7">
                        <div class="label">承保单号:</div>
                        <div class="value">{{data.policyNo}}</div>
                    </div>

                    <div class="order-info-item">
                        <div class="label">保障期限:</div>
                        <div class="value">{{data.policyBeginDate +' 至'}}<br/>{{ this.$util.formatTime(data.policyEndDate, 'yyyy-MM-DD 24:00:00') }}</div>
                    </div>
                </van-col>

                <div @click="showOrderAction=true" class="order-status">
                    <div class="circle circle1"></div>
                    <span class="order-status-text" style=" color: #999">{{data.statusGroupName}}</span>
                </div>
            </van-row>
            <div class="order-last-action">
                <!--<span style=" color: #07c160">{{data.statusGroupName}}</span>-->

                <span class="price-text">保费: <b class="price-amount">¥{{this.$util.formatMoneyNoZero(data.totalPremiumAmountYuan)}}</b>元</span>
            </div>
        </RoundCornerContainer>

        <!-- 操作记录 -->
        <van-popup class="popup-order-action" closeable position="bottom" v-model="showOrderAction">
            <van-steps :active="data.orderActions.length-1" active-color="#2991fa" active-icon="success"
                       direction="vertical">
                <van-step :key="index" class="step_item" v-for="(item,index) in data.orderActions">
                    <h3>{{item.actionText}}</h3>
                    <p>{{item.actionTime|formatTime}}</p>
                </van-step>
            </van-steps>
        </van-popup>

        <RoundCornerContainer class="containter" title="投保人信息" v-if="data.proposer && !data.enterprise">
            <van-cell>
                <div class="row" v-if="data.proposer.name">
                    <div class="address_title">投保人</div>
                    <div class="address_content">{{data.proposer.name}}</div>
                </div>
                <div class="row" v-if="data.proposer.identityTypeText">
                    <div class="address_title">证件类型</div>
                    <div class="address_content">{{data.proposer.identityTypeText}}</div>
                </div>
                <div class="row" v-if="data.proposer.identityNo">
                    <div class="address_title">证件代码</div>
                    <div class="address_content">{{data.proposer.identityNo}}</div>
                </div>
                <div class="row" v-if="data.proposer.birthday">
                    <div class="address_title">出生日期</div>
                    <div class="address_content">{{data.proposer.birthday}}</div>
                </div>
                <div class="row" v-if="data.proposer.phone">
                    <div class="address_title">手机号码</div>
                    <div class="address_content">{{data.proposer.phone}}</div>
                </div>
                <div class="row" v-if="data.proposer.email">
                    <div class="address_title">邮箱地址</div>
                    <div class="address_content">{{data.proposer.email}}</div>
                </div>
            </van-cell>
        </RoundCornerContainer>
        <RoundCornerContainer class="containter" title="投保企业" v-if="data.enterprise">
            <van-cell>
                <div class="row" v-if="data.enterprise.name">
                    <div class="address_title">企业名称</div>
                    <div class="address_content">{{data.enterprise.name}}</div>
                </div>
                <div class="row" v-if="data.enterprise.identityTypeText">
                    <div class="address_title">证件类型</div>
                    <div class="address_content">{{data.enterprise.identityTypeText}}</div>
                </div>
                <div class="row" v-if="data.enterprise.identityNo">
                    <div class="address_title">证件号码</div>
                    <div class="address_content">{{data.enterprise.identityNo}}</div>
                </div>
                <div class="row" v-if="data.enterprise.creditCode">
                    <div class="address_title">社会信用代码</div>
                    <div class="address_content">{{data.enterprise.creditCode}}</div>
                </div>
                <div class="row" v-if="data.enterprise.cityText">
                    <div class="address_title">企业所在地</div>
                    <div class="address_content">{{data.enterprise.provinceText?data.enterprise.provinceText:''}}{{data.enterprise.cityText}}</div>
                </div>
                <div class="row" v-if="data.enterprise.address">
                    <div class="address_title">企业详细地址</div>
                    <div class="address_content">{{data.enterprise.address}}</div>
                </div>
                <div class="row" v-if="data.enterprise.industryName">
                    <div class="address_title">所属行业</div>
                    <div class="address_content">{{data.enterprise.industryName}}</div>
                </div>
            </van-cell>
        </RoundCornerContainer>
        <RoundCornerContainer class="containter" title="企业联系人" v-if="data.enterprise">
            <van-cell>
                <div class="row" v-if="data.enterprise.contactName">
                    <div class="address_title">联系人姓名</div>
                    <div class="address_content">{{data.enterprise.contactName}}</div>
                </div>
                <div class="row" v-if="data.enterprise.contactMobile">
                    <div class="address_title">联系人手机号</div>
                    <div class="address_content">{{data.enterprise.contactMobile}}</div>
                </div>
                <div class="row" v-if="data.enterprise.email">
                    <div class="address_title">联系人邮箱</div>
                    <div class="address_content">{{data.enterprise.email}}</div>
                </div>
            </van-cell>
        </RoundCornerContainer>

        <RoundCornerContainer class="containter" title="被保人信息" v-if="data.insurer">
            <van-cell>
                <div class="row" v-if="data.insurer.name">
                    <div class="address_title">被保人</div>
                    <div class="address_content">{{data.insurer.name}}</div>
                </div>
                <div class="row" v-if="data.insurer.identityTypeText">
                    <div class="address_title">证件类型</div>
                    <div class="address_content">{{data.insurer.identityTypeText}}</div>
                </div>
                <div class="row" v-if="data.insurer.identityNo">
                    <div class="address_title">证件代码</div>
                    <div class="address_content">{{data.insurer.identityNo}}</div>
                </div>
                <div class="row" v-if="data.insurer.birthday">
                    <div class="address_title">出生日期</div>
                    <div class="address_content">{{data.insurer.birthday}}</div>
                </div>
                <div class="row" v-if="data.insurer.phone">
                    <div class="address_title">手机号码</div>
                    <div class="address_content">{{data.insurer.phone}}</div>
                </div>
                <div class="row" v-if="data.insurer.email">
                    <div class="address_title">邮箱地址</div>
                    <div class="address_content">{{data.insurer.email}}</div>
                </div>
                <van-divider />

                <div class="row" v-if="data.insurer.relationShipProposer || data.insurer.relationShipProposer===0">
                    <div class="address_title">与投保人关系</div>
                    <div class="address_content">{{relationShipMap[data.insurer.relationShipProposer]}}</div>
                </div>

                <div class="row" v-if="data.insurer.professionName">
                    <div class="address_title">职业</div>
                    <div class="address_content">{{data.insurer.professionName}}</div>
                </div>
                <div class="row" v-if="data.insurer.professionType">
                    <div class="address_title">职业类别</div>
                    <div class="address_content">{{professionTypeMap[data.insurer.professionType]}}</div>
                </div>

                <!--     pingAnHealth 扩展字段           -->
                <div class="row" v-if="extendData.isSocial">
                    <div class="address_title">有无社保</div>
                    <div class="address_content">{{isSocialMap[extendData.isSocial]}}</div>
                </div>
                <div class="row" v-if="extendData.medicalType">
                    <div class="address_title">医保类型</div>
                    <div class="address_content">{{medicalTypeMap[extendData.medicalType]}}</div>
                </div>

                <!--     pingAn 学平险 扩展字段           -->
                <div class="row" v-if="extendData.education">
                    <div class="address_title">学校类型</div>
                    <div class="address_content">{{educationMapping[extendData.education]}}</div>
                </div>
                <div class="row" v-if="extendData.school">
                    <div class="address_title">学校</div>
                    <div class="address_content">{{extendData.school}}</div>
                </div>
            </van-cell>
        </RoundCornerContainer>
        <!--多方案-->
        <RoundCornerContainer :key="'list'+index" :title="item.packageName" class="containter teamLink"
                              style="padding-bottom: 0" v-for="(item,index) in data.packageVOList">
            <van-cell :border="false">
                <div class="row">
                    <div class="address_title">职业类别</div>
                    <div class="address_content">{{item.professionName.split(',').pop()}}</div>
                </div>
            </van-cell>

            <van-cell :border="false" :center="true" @click="showPlanDetail(item.insureItems)" isLink>
                <div class="row">
                    <div class="address_title">保障方案</div>
                    <div class="address_content">方案详情</div>
                </div>
            </van-cell>

            <van-cell :border="false" :center="true"
                      @click="toStaffList(JSON.stringify(item.insurers),'4')"
                      isLink>
                <div class="row">
                    <div class="address_title">投保人数</div>
                    <div class="address_content">{{item.insureNum}}人</div>
                </div>
            </van-cell>

            <p class="price">总计保费：<span>&yen;{{$util.formatMoneyNoZero(item.totalAmount / 100)}}</span>
                （&yen;{{$util.formatWallet(item.averagePremium / 100)}}/人）</p>
        </RoundCornerContainer>


        <RoundCornerContainer title="受益人">
            <!--<div class="no-content">法定</div>-->
            <van-cell style="padding:5px 15px ;">
                <div class="row">
                    <div class="address_title">法定</div>
                    <div class="address_content"></div>
                </div>
            </van-cell>
        </RoundCornerContainer>

        <!--<RoundCornerContainer class="containter teamLink" title="批单历史"-->
        <!--v-if="data.correctVOList && data.correctVOList.length >= 1">-->
        <!--<van-cell :center="true"-->
        <!--:key="'correctList'+index"-->
        <!--:to="{path: '/team/order/detail', query: {correctId: item.id}}" isLink-->
        <!--v-for="item,index in data.correctVOList">-->
        <!--<div class="row">-->
        <!--<div class="address_title">批单号：{{item.correctNo}}</div>-->
        <!--<div class="address_content">{{item.correctApplyTime.substring(0,11)}}</div>-->
        <!--</div>-->
        <!--</van-cell>-->

        <!--</RoundCornerContainer>-->

        <RoundCornerContainer title="电子保单" v-if="data.statusGroup >= 7 && urls">
            <div class="epolicy" v-if="isHavePolicy">
                <van-cell :title="item.fileName" @click="previewDocUrl(item.fileName,item.fileUrl)"
                          v-for="(item,index) in urls" :key="index" is-link/>
            </div>
            <div v-else>
                <van-cell style="padding:10px 15px;color:#333;">
                    <van-loading v-if="!downloadFinish" size="24px">正在获取电子保单,请稍后...</van-loading>
                    <div v-if="downloadFinish" >保单加载有误，请刷新页面</div>
                </van-cell>
            </div>
        </RoundCornerContainer>

<!--        <RoundCornerContainer title="" v-if="data.statusGroup >= 7">-->
<!--            <van-cell @click="toStaffList(JSON.stringify(personList),'3')" isLink-->
<!--                      :value="(personList?personList.length:'0')+'人'"-->
<!--                      style="padding:10px 15px ;"-->
<!--                      title="最新人员清单"></van-cell>-->
<!--        </RoundCornerContainer>-->

        <!--<BottomBarContainer class="teambottom">-->
            <!--<div class="bottom-bar-text">-->
                <!--<span class="price-text">保费: <b class="price-amount">¥{{this.$util.formatMoneyNoZero(data.totalPremiumAmountYuan)}}</b>元</span>-->
            <!--</div>-->
        <!--</BottomBarContainer>-->

        <!-- 图标位置 -->
        <van-popup
            v-model="showplan"
            close-icon-position="top-left"
            position="bottom"
            :style="{ height: '30%' }"
        >
            <div class="plandetailbox">
                <van-row>
                    <van-col :span="8">险种名称</van-col>
                    <van-col :span="14">保障内容</van-col>
                </van-row>
                <van-row v-for="li in plandetail">
                    <van-col :span="8">{{li.title}}</van-col>
                    <van-col :span="14">{{li.value}}</van-col>
                </van-row>
            </div>

        </van-popup>

        <van-row class="product-bottom-bar" v-if="data.statusGroup == 3">
            <van-col offset="1" span="17">
                <div class="pay-amount-block">
                    <span class="pay-amount">支付金额: ¥{{data.totalPremiumAmountYuan}}</span>
                </div>
            </van-col>
            <van-col span="6">
                <van-button type="danger" class="btn-pay" @click="handlePay">支付</van-button>
            </van-col>
            <!--              <van-col span="6">-->
            <!--                <van-button type="danger" @click="testChudan">出单</van-button>-->
            <!--              </van-col>-->
        </van-row>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import { WidgetEngine } from '@/modules/life/component/common/WidgetEngine';
    import RoundCornerContainer from "@/modules/life/component/RoundCornerContainer";
    import BottomBarContainer from '@/modules/life/component/BottomBarContainer';

    export default {
        components: {
            RoundCornerContainer,
            BottomBarContainer
        },
        name: "order_detail",
        data () {
            return {
                // 保障计划
                showplan: false,
                plandetail: null,
                // 展示操作记录
                showOrderAction: false,

                personList: [],//人员清单
                data: {
                    orderItems: [],
                    orderFiles: [],
                    orderActions: [],
                    orderHandle: {},
                    proposer: {},
                    insurer: {},
                    beneficiaries: [],
                    product: {},
                    productPlan: {},
                    payInfo: {},
                    vehicle: null,
                    vehicleOwner: null,
                    enterprise: {},
                    insurers: [],
                    correctVOList: null
                },
                extendData:{},
                orderNo: '',
                orderCategory: '',
                loading: false,
                product: [],

                urls: null,
                isHavePolicy:false,
                downloadFinish:false,

                relationShipMap: {
                    '0':'本人',
                    '1':'父母',
                    '2':'配偶',
                    '3':'子女',
                    '4':'祖父母',
                    '5':'孙/外孙',
                    '6':'赡养',
                    '7':'雇佣',
                    '8':'其他',
                },
                professionTypeMap: {
                    '1':'一类',
                    '2':'二类',
                    '3':'三类',
                    '4':'四类',
                    '5':'五类',
                    '6':'六类',
                },
                isSocialMap: {
                    '1':'有',
                    '0':'否',
                },
                medicalTypeMap: {
                    '1':'城乡居民医保',
                    '2':'职工医保',
                    '3':'新型农村合作医疗',
                },
                educationMapping: {
                    '1': '幼儿园',
                    '2': '小学',
                    '3': '中学',
                    '4': '高中',
                    '5': '大学',
                    '6': '技工学校',
                    '7': '舞蹈学校',
                    '8': '大专',
                    '9': '中专',
                    '10': '医院学校',
                },

            }
        },
        computed: {
            ...mapGetters({}),
        },
        mounted () {
            //this.getData();
            this.orderNo = this.$route.query.orderNo;
            this.orderCategory = this.$route.query.orderCategory;
            if (!this.orderNo) {
                this.orderNo = this.$route.query.orderSn;
            }

            if (this.$util.isEmpty(this.orderNo)) {
                this.$tips.toast("缺少订单编号");
                this.$router.back(-1);
            }
            this.getOrderGYInfo()

        },
        beforeDestroy () {
            this.$tips.hideLoading();
        },
        destroyed () {
        },
        methods: {
            // 展示保障计划详情
            showPlanDetail (val) {
                this.showplan = !this.showplan;
                this.plandetail = val;
            },

            //pdf预览
            previewDocUrl (title, link) {
                window.location.href = link;
            },

            // 人员列表
            toStaffList (obj, tag) {
                localStorage.setItem('insurers', obj);
                this.goUrl('/team/staff?tag=' + tag + '&orderNo=' + this.data.orderNo);
            },

            goUrl (link) {
                this.$util.jumpLink(link);
            },

            // 获取最新人员信息
            getNewestList (ordNo) {
                this.loading = true;
                this.$tips.showLoading();
                this.$api.life.order_newestPersonList({orderNo: ordNo})
                    .then((response) => {
                        this.$tips.hideLoading();
                        this.loading = false;
                        if (response.code == 0) {
                            this.personList = response.data;

                        } else {
                            this.$tips.toast(response.msg);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        this.$tips.hideLoading();
                        this.loading = false;
                        this.$tips.toast('系统正忙，请稍后再试');
                    });
            },

            // 获取订单详情
            getOrderGYInfo () {
                this.loading = true;
                this.$tips.showLoading();

                this.$api.life.order_detail_GY({orderNo: this.orderNo})
                    .then((response) => {
                        this.$tips.hideLoading();
                        this.loading = false;
                        if (response.code == 0) {
                            this.data = response.data;

                            this.urls = []
                            if (this.data.orderFiles && this.data.orderFiles.length > 0) {
                                this.data.orderFiles.forEach(item => {
                                    if (item.fileGroup == 4) {
                                        this.urls.push({
                                            fileName: item.fileName,
                                            fileUrl: item.fileUrl
                                        })
                                    }
                                })
                                this.isHavePolicy = true;
                            }else{
                                console.log("保单为空")
                                if(this.data.statusGroup === 7 && this.data.productLibrary.extraDataVO.policyActiveDownloads===1){
                                    this.urls = [];
                                    this.isHavePolicy = false;
                                    this.$api.life.download_policy_gy({orderNo: this.orderNo}).then((response) => {
                                        this.isHavePolicy = true;
                                        if (response.code == 0) {
                                            this.urls.push({
                                                fileName:  response.data.policyFileName,
                                                fileUrl: response.data.policyUrl
                                            })
                                        }
                                        this.isHavePolicy = true;
                                        this.downloadFinish = true;
                                    }).catch((error) => {
                                        this.downloadFinish = true;
                                        console.log(error);
                                    });
                                }


                            }

                            this.data.insurer = response.data.insurers[0];
                            this.extendData = JSON.parse(response.data.insurers[0].extendData);
                            console.log(this.data.insurer)
                            // 已完成获取最新人员信息
                            // if (this.data.statusGroup >= 7) {
                            //     this.getNewestList(this.data.orderNo);
                            // }

                            this.loadProductDetail()
                        } else {
                            this.$tips.toast(response.msg);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        this.$tips.hideLoading();
                        this.loading = false;
                        this.$tips.toast('系统正忙，请稍后再试');
                    });
            },

            loadProductDetail () {
                //产品信息
                this.loading = true;
                this.$api.life.product_detail(this.data.productCode).then((res) => {
                    this.loading = false;
                    if (res.code == 0) {
                        this.product = res.data;
                        this.planInsureItem();

                        // this.templateForm = JSON.parse(this.product.template.templateForm);
                        //
                        // for (let i=0; i< this.data.packageVOList.length; i++) {
                        //     let p =this.data.packageVOList[i];
                        //     p.insureItems = WidgetEngine.sharedInstance.parsePlan(this.templateForm.widgets, p.quoteParams, null, null);
                        //
                        // }

                    }
                }).catch((error) => {
                    this.loading = false;
                })
            },

            planInsureItem () {
                if (!this.data.insureParams) {
                    return;
                }
                let insureParams = JSON.parse(this.data.insureParams);
                if (insureParams.orderPackages
                    && insureParams.orderPackages.length > 0) {

                    for (let index in insureParams.orderPackages) {
                        let plan = insureParams.orderPackages[index];
                        let planName = plan.planName;
                        let quoteParams = plan.quoteParams;
                        let items = [];
                        if (this.product
                            && this.product.template
                            && this.product.template.templateForm) {
                            let templateForm = JSON.parse(this.product.template.templateForm);
                            let widgets = templateForm.widgets;

                            for (let widgetKey in widgets) {
                                let widget = widgets[widgetKey];
                              //表单不可用就隐藏
                              if (widget.visible === false) {
                                continue;
                              }
                                if (widget.quoteNotShow && widget.quoteNotShow == 1) {
                                    continue;
                                }
                                let showValue = '';

                                if (widget.options) {
                                    for (let optionsKey in widget.options) {
                                        let option = widget.options[optionsKey];
                                        let paramValue = '';
                                        for (var param in quoteParams) {
                                            if (param == widget.dataBind) {
                                                paramValue = quoteParams[param];
                                            }
                                        }
                                        if (option.value == paramValue) {
                                            showValue = option.label
                                        }
                                    }
                                }

                                if (widget.widget === 'number'
                                    || widget.widget === 'text'
                                    || widget.widget === 'textarea') {
                                    showValue = quoteParams[widget.dataBind];
                                }

                                if (widget.widget === 'info') {
                                    showValue = widget.value
                                }

                                if (widget.widget === 'switch') {
                                    let v = quoteParams[widget.dataBind];
                                    showValue = "不投保";
                                    if (v) {
                                        showValue = v == 1 ? "投保" : "不投保"
                                    }
                                }
                                let item = {
                                    title: widget.label,
                                    value: showValue
                                };
                                items.push(item);
                            }
                        }

                        this.data.packageVOList.forEach(item => {
                            if (item.packageName == planName) {
                                item.insureItems = items;
                            }
                        });
                    }
                }
            },
            handlePay () {
                let payForm = {};
                payForm.orderNo = this.orderNo;
                payForm.terminalType = this.$util.isMobileWeixin() ? 'mobile' : 'pc';
                this.$tips.showLoading("正在生成支付链接...");
                this.loading = true;
                this.$api.life.order_pay({orderNo: this.orderNo}).then((response) => {
                    this.jumping = true;
                    this.loading = false;
                    this.$tips.clearLoading();
                    if (response.code == 0) {
                        window.location.href = response.data.payUrl;
                    } else {
                        this.$tips.alert("投保提示: ", response.msg);
                    }
                }).catch((error) => {
                    this.loading = false;
                    this.$tips.clearLoading();
                    console.log(error);
                });
            },
        }
    }
</script>
<style lang="scss">
    .van-step--vertical .van-step__circle-container {
        left: -15px;
        font-size: 16px;
        -webkit-transform: translate(-46%, -50%);
        transform: translate(-46%, -50%);
    }

    .price-text {
        padding: 0 !important;
        line-height: 28px !important;
    }

    .price-text .price-amount {
        color: #ff5e69;
        font-size: 0.45333rem;
        font-weight: 500;
        margin-right: 0.10667rem;
    }
</style>
<style lang="scss" scoped>
    .plandetailbox {
        width: 100%;
        padding: 15px;
        .van-row {
            width: 100%;
            line-height: 26px;
            font-size: 14px;
            text-align: center;
            margin-bottom: 10px;
            &:first-child {
                background: #f1f1f1;
            }
        }
    }

    .order-detail {
        padding-top: 10px;

        .planbox {
            background: #f5f9fe;
            padding-left: 10px;
            border-top: 1px dashed #ddd;
            border-bottom: 1px dashed #ddd;

            .van-cell {
                background: #f5f9fe;

                &:after {
                    border-bottom: 0 !important;
                }
            }
        }

        .teamLink {
            .van-cell {
                color: #666;
                font-size: 13px;
                line-height: 32px;

                &:after {
                    border-bottom: 1px dashed #ddd;
                }

                .van-cell__right-icon {
                    height: 32px;
                    font-size: 0.42667rem;
                    line-height: 32px;
                }

                .van-cell__title {
                    flex: 3;
                }
            }

            .other {
                background: #f5f9fe;
            }

            .detail {
                background: #fff;

                .van-cell__value, .van-cell__right-icon {
                    color: #1e8ffa;
                }
            }

            .price {
                background: #fafafa;
                text-align: right;
                padding: 10px 15px;
                margin: 0;
                font-size: 12px;
                color: #444;

                span {
                    font-size: 17px;
                    color: #ff6666;
                }
            }
        }

        .order-header {
            margin-top: 10px;
            background: #fff;
            padding: 10px 10px 5px;
            line-height: 20px;
            position: relative;

            .product-name {
                width: 70%;
                margin: 0 0 8px;
                font-size: 18px;
                font-weight: 500;
            }

            .order-info-item {
                padding: 0 !important;
                font-size: 12px;
                line-height: 25px;
                display: flex;

                .label {
                    /*width: 60px;*/
                    padding: 0 5px 0 0;
                    color: #666;
                    font-size: 13px;
                }

                .value {
                    text-align: left;
                }
            }

            .order-status {
                position: absolute;
                right: 10px;
                top: 15px;
                width: 80px;
                height: 80px;
                border-radius: 50%;
                background: url("~@/assets/images/life/bg_mark.png") no-repeat center center;
                background-size: cover;

                .circle {
                    position: absolute;
                    right: 0px;
                    top: 0px;
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;

                    &.circle1 {
                        width: 65px;
                        height: 65px;
                        left: 2px;
                        top: 2px;
                    }

                    &.circle2 {
                        width: 60px;
                        height: 60px;
                        left: 4px;
                        top: 4px;
                    }
                }

                .order-status-text {
                    position: absolute;
                    top: 25px;
                    left: -7px;
                    display: inline-block;
                    color: #999;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 30px;
                    width: 92px;
                    background: transparent;
                    text-align: center;
                    transform: rotate(30deg);
                }

                .van-icon {
                    position: absolute;
                    right: 0px;
                    top: 2px;
                    color: #999;
                }
            }

            .order-last-action {
                width: 70%;
                padding: 6px;
                position: relative;
                border-top: dotted 1px #eee;
                margin-top: 10px;
            }

           ::v-deep .van-steps__items {
                margin-bottom: 0px;
            }
        }

       ::v-deep .van-step--finish .van-step__circle {
            background-color: #2991fa;
        }

       ::v-deep .van-step--finish .van-step__line {
            background-color: #2991fa;
        }
    }

    .card-container {
        .van-cell {
            padding: 0 15px;
        }

        .address_title {
            /*width: 130px;*/
            padding: 0;
            color: #666;
            font-size: 13px;
        }

        .address_content {
            flex: 1;
            word-break: break-all;
            text-align: right;
        }

        .row {
            width: 100%;
            display: flex;
            padding: 3px 0;
            font-size: 13px;
        }

        .priceAll {
            text-align: right;
            font-size: 13px;
            color: #454545;
            line-height: 40px;
            padding: 0 15px 0px;
            margin: 0;

            span {
                font-size: 14px;
                color: #ff6666;
            }

            background-color: white;
        }

        &.attachment {
            .van-cell {
                padding: 10px 10px;
                font-size: 13px;
                color: #454545;
            }
        }

        .no-content {
            line-height: 40px;
            text-align: left;
            padding-left: 20px;
        }
    }

    .btn-blue {
        background: #1e8ffa;
        color: #fff;
        border-color: #1e8ffa;
    }

    .product-bottom-bar {
        box-shadow: 0 -4px 10px hsla(0, 0%, 60%, .1);
        position: fixed;
        bottom: 0px;
        left: auto;
        width: 100%;
        background-color: white;
        z-index: 100;

        .price {
            font-size: 18px;
            color: #ff771c;
            font-weight: 500;
        }

        .van-button {
            border-radius: 0px;
            width: 100%;
            /*background: #fff;*/
            /*border: 1px #fff solid*/
        }

        .btn-service {
            border-right: solid 1px #eee;
            font-size: 18px;
        }

        .bottom-amount {
            width: 100%;
            height: 100%;
            text-align: center;
            padding-left: 10px
        }

        .btn-back {
            border-right: 1px #eee solid;

            .van-icon {
                font-size: 14px
            }
        }

        .btn-pay {
            background: #1e8ffa;
            border: 1px #1e8ffa solid
        }
    }

    .benefit-title {
        font-size: 14px;
        color: #969696;
        padding-right: 15px;
    }

    .address_content {
        flex: 1;
        word-break: break-all;
        text-align: right;
    }

    .row {
        width: 100%;
        display: flex;
        padding-top: 8px;
    }

    .group_title {
        margin: 0;
        font-size: 16px;
        font-weight: 400;
    }

    .step_item {
        h3 {
            font-size: 14px;
            font-weight: normal;
            padding: 5px;
            margin: 0px
        }

        p {
            font-size: 14px;
            color: #666;
            margin: 5px
        }
    }

    .bottom-insure-again {
        text-align: center;
        color: #999;

        .btn-link {
            text-align: center;
            border: none;
            background: transparent;
            display: inline-block;
            margin: 5px auto;
            color: $blue;
        }
    }

    .buttons {
        .btn-orange {
            background-color: #4b0;
            color: #fff;
            border-radius: 3px;
            border: 0;
            margin-left: 5px;
            margin-right: 5px;
            padding: 5px 10px;
        }

        .btn-red {
            color: #fff;
            background-color: #e64340;
            border-radius: 3px;
            border: 0;
            margin-left: 5px;
            margin-right: 5px;
            padding: 5px 10px;
        }

        .btn-green {
            color: #fff;
            background-color: #4b0;
            border-radius: 3px;
            border: 0;
            padding: 5px 10px;
            margin-left: 5px;
            margin-right: 5px;
        }

        .btn-blue {
            float: right;
            padding: 5px 10px;
            background-color: #1a92ee;
            color: #fff;
            border-radius: 3px;
            border: 0;
            margin-left: 5px;
            margin-right: 5px;
        }
    }

    /* 可以设置不同的进入和离开动画 */
    /* 设置持续时间和动画函数 */
    .slide-fade-enter-active {
        transition: all .5s ease;
    }

    .slide-fade-leave-active {
        transition: all .5s ease;
    }

    .slide-fade-enter, .slide-fade-leave-to
        /* .slide-fade-leave-active for below version 2.1.8 */
    {
        transform: translateY(-10px);
        opacity: 0;
    }

    .load-more {
        padding: 4px 0;
        text-align: center;
    }

    .sign-image {
        width: 100%;
    }

    .insurer-name {
        color: #e4af63;
        padding-left: 5px;
        padding-right: 5px;
        font-weight: 500;
    }

    .confirm-title {
        font-size: 16px;
    }

    .preview-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: calc(100% - 40px);
        padding: 0px;
        background: #f1f1f1;

        .frame-preview {
            width: 100%;
            height: 100%;
            margin: 0;
            padding: 0;
            border: none;
            background-color: transparent;
            /*position: absolute;*/
            /*top: 0;*/
            /*left: 0;*/
            /*z-index: 100;*/
        }

        .bottom-btn {
            height: 40px;
            position: absolute;
            bottom: 0px;
            display: flex;
            width: 100%;
            z-index: 100;

            .btn-read {
                background-color: $btn-main;
                color: #fff;
                border-radius: 0px;
            }
        }

    }

    .confirm-title {
        font-size: 16px;
    }

    .insurer-name {
        color: #e4af63;
        font-weight: 500;
        padding: 0px;
    }

    .confirm-product-name {
        color: $blue;
        font-weight: 500;
        padding: 0px;
    }

    .confirmed-tips {
        color: $blue;
        font-size: 16px;
        text-align: center;
        font-weight: 400;
    }

    .confirm-tips-warning {
        background-color: #fffae7;
        border-color: #fffae7;
        color: rgba(0, 0, 0, 1);
        padding: 5px;
        text-align: justify;
        text-justify: inter-ideograph;
    }

    .tips-p {
        padding-left: 5px;
        margin: 0px;
    }

    .btn-single {
        line-height: 250%;
        font-size: 15px;
        height: 100%;
        width: 100%;
        border: none;
        background: #2991fa;
        color: #FFFFFF;
        font-weight: 400;
        border-radius: 60px 60px 60px 60px;
    }

    .pay-amount {
        color: $orange;
    }

    .money {
        font-size: 18px;
    }

    .containter {
        padding-bottom: 10px;
        background-color: white;
    }

    .button-container {
        padding-left: 10px;
        padding-right: 10px;
    }

    .popup-order-action {
        max-height: calc(100% - 150px);
    }

    .epolicy {
        width: 100%;
        padding: 10px 0;

        span {
            color: #648df6;
            display: inline-block;
            width: 100%;
        }
    }

    .urlbox {
        width: 80%;
        padding: 15px;
        border-radius: 4px;
        font-size: 14px;
        img {
            width: 80px;
            margin: 0 auto;
            display: block;
        }
        p {
            color: #333;
            margin: 20px 0 20px 0;
        }
        div {
            color: #666;
        }
        .van-button {
            width: 100%;
            height: 40px;
            line-height: 40px;
            margin-top: 15px;
            font-size: 14px;
            background: $whiteBlue;
            border-color: $whiteBlue;
        }
    }

    .product-bottom-bar {
        box-shadow: 0 -4px 10px hsla(0, 0%, 60%, .1);
        position: fixed;
        bottom: 0px;
        width: 100%;
        background-color: white;
        z-index: 100;
    }

    .pay-amount-block .pay-amount {
        font-size: 15px;
        margin-top: 10px;
        font-weight: 500;
        display: block;
        color: #333;
        font-family: sans-serif;
    }
</style>
